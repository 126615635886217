
@font-face {
  font-family: 'Mont-Light';
  src: url('../Fonts/Montserrat-Light.ttf');
}
@font-face {
  font-family: 'Mont-Medium';
  src: url('../Fonts/Montserrat-Medium.ttf');
}
@font-face {
  font-family: 'Mont-Regular';
  src: url('../Fonts/Montserrat-Regular.ttf');
}
@font-face {
  font-family: 'uni-thin'; 
  src: url('../Fonts/Uni\ Sans\ Thin.otf');
}
@font-face {
  font-family: 'uni-Regular';
  src: url('../Fonts/Uni\ Sans\ Heavy.otf');
}

body{
  background-color: #191a1f;
}
.containerr {
  position: relative;
}

/* .first-section {
  background-image: url(../images/banner.png);
  
  background-size: cover; 
  height: auto;
  background-position: center; 
  padding: 20px 20px 169px 20px; 
  transition: background-color 0.3s ease; 
  box-shadow: 10px 7px 50px 33px black;
} */


.first-section {
  position: relative;
  height: auto;
  overflow: hidden;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;

}







h1,p{
  margin: 0px;
}
.humb-icon{
  width: 30px;
  height: 35px;
} 
.humb-icon-cross{
 width: 30px;
  height: 35px;
      color: white;

}

.logo img{
  width: 150px;
  height: 90px;
  padding: 10px 0px ;
}

.sideMenue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 10px;
}
.fake-menu{
    list-style: none;
    padding: 21px;
    font-size: 18px;
    font-family: 'Mont-Light';
    color: white;
}
.fake-menu:hover{
  color: #c38b74;
}
.sidebar {
  position: fixed;
  top: 0;
  left: -450px; /* Initially hide the sidebar off the screen */
  width: 450px;
  height: 100vh;
  background-color: black; /* Set sidebar background color */
  transition: left 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
  opacity: 95%;
  z-index: 999;
}

.sidebar.open {
  left: 0; /* Slide the sidebar in */
}

.humb-icon-cross{
  padding: 0px 0px 0px 30px;
}
.sidebar-content {
  padding-top: 50px; /* Adjust top padding to account for logo height */
}

.bannercontent {
  color: white;
  text-align: center;
  padding: 30px 220px;
}

.sidebar.open ~ .first-section {
  background-color: rgba(0, 0, 0, 0.5); /* Dim background color of main section */
}

/* Other styles for your content elements */
.row-1 p{
  font-size: 40px;
  font-weight: 100;
  font-family: "uni-thin";
}
.row-2 p{
  font-size: 40px;
  font-weight: bolder;
  font-family: "Mont-Regular";
  padding: 10px 0px;
}
.row-3 p{
    font-size: 20px;
  font-weight: 100;
  font-family: "Mont-light";
  padding: 10px 0px;
}
.row-4{
  display: flex;
  justify-content: space-evenly;
}
.Start-price img{
  width: 80px;
  height: 80px;
  float: left;
}
.Start-price{
  width: 300px;
}

.Start-price p{
  text-align: left;
  font-family:"Mont-light";

}
.End-price{
    width: 300px;
    margin-left:"30px" ;

}
.End-price img{
  width: 80px;
  height: 80px;
  float: left;
}
.End-price p{
  text-align: left;
  font-family:"Mont-light";
}
.row-5 button{
      padding: 14px 40px;
    border-radius: 20px;
    border: 1px solid grey;
    color: black;
    margin-bottom: 20px;
}
.AED-content{
  margin-top: 30px;
  font-weight: 800;
  font-size: 20px;
}
.END-content{
  margin-top: 30px;
  font-weight: 800;
  font-size: 20px;
}

.search-filter{
  
    width: 100%;
    text-align: center;
}
.inner-box-filter{
    background: #000000ab;
    height: auto;
    border-radius: 27px;
    padding: 15px 10px;
    margin: 10px 55px;
}
.inner-box-filter select{
    margin: 10px;
    background: none;
    color: white;
    border: 1px solid rgb(204, 204, 204);
    font-size: 16px;
    border-radius: 4px;
    padding: 4px 0px;
}
.inner-box-filter input{
    margin: 10px;
    background: none;
    color: white;
    border: 1px solid rgb(204, 204, 204);
    font-size: 16px;
    border-radius: 4px;
    padding: 4px 0px;
}
.filter-btn{
    margin: 10px;
    background-color: #c38b74;
    color: black;
    border: 1px solid rgb(204, 204, 204);
    font-size: 16px;
    border-radius: 4px;
    width: 90%;
    padding: 4px 0px;
}
.filter-btn:hover{
    background-color: #fa7f4e;
}
@media screen and (max-width: 1050px) {
.row-1 p{
  font-size: 30px;
  font-weight: 100;
  font-family: "uni-thin";
}
.row-2 p{
  font-size: 30px;
  font-weight: 800;
  font-family: "Mont-Regular";
  padding: 10px 0px;
}
.row-3 p{
    font-size: 18px;
  font-weight: 100;
  font-family: "Mont-light";
  padding: 10px 0px;
}

.End-price img{
  width: 45px;
  height: 45px;
  float: left;
}
.Start-price img{
  width: 45px;
  height: 45px;
  float: left;
}
.AED-content{
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
}
.END-content{
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
}
.row-5 button{
    margin-top: 30px;
    padding: 14px 40px;
    border-radius: 20px;
    border: 1px solid grey;
    margin-bottom: 20px;
}

.bannercontent {
  color: white;
  text-align: center;
  padding: 30px 70px;
}

  .thr-img img{
        width: 70rem;
  }
}

@media screen and (max-width: 860px) {
.row-1 p{
  font-size: 25px;
  font-weight: 100;
  font-family: "uni-thin";
}
.row-2 p{
  font-size: 25px;
  font-weight: 500;
  font-family: "Mont-Regular";
  padding: 7px 0px;
}
.row-3 p{
  font-size: 14px;
  font-weight: 100;
  font-family: "Mont-light";
  padding: 7px 0px;
}

.End-price img{
  width: 45px;
  height: 45px;
  float: left;
}
.Start-price img{
  width: 45px;
  height: 45px;
  float: left;
}
.AED-content{
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
}
.END-content{
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
}
.row-5 button{
    margin-top: 30px;
    padding: 14px 40px;
    border-radius: 20px;
    border: 1px solid grey;
}

.bannercontent {
  color: white;
  text-align: center;
  padding: 30px 70px;
}
  .thr-img img{
        width: 70rem;
  }

}

@media screen and (max-width: 630px) {
.row-1 p{
  font-size: 15px;
  font-weight: 100;
  font-family: "uni-thin";
}
.row-2 p{
  font-size: 20px;
  font-weight: 500;
  font-family: "Mont-Regular";
  padding: 10px 0px;
}
.row-3 p{
  font-size: 14px;
  font-weight: 100;
  font-family: "Mont-light";
  padding: 10px 0px;
}
.row-4{
  display: flex;
  justify-content: space-evenly;
  padding: 0px 96px;
  padding-left: 120px;

}

.End-price img{
  width: 45px;
  height: 45px;
  float: left;
}
.Start-price img{
  width: 45px;
  height: 45px;
  float: left;
}
.End-price p{
  text-align: left;
  font-family:"Mont-light";
  font-size: 10px;

}
.Start-price p{
  text-align: left;
  font-family:"Mont-light";
  font-size: 10px;
}
.AED-content{
  margin-top: 10px;
  font-weight: 100;
  font-size: 10px;
}
.END-content{
  margin-top: 10px;
  font-weight: 100;
  font-size: 10px;
}
.row-5 button{
    margin-top: 30px;
    padding: 8px 18px;
    border-radius: 20px;
    font-size: 10px;
    border: 1px solid grey;
}

.bannercontent {
  color: white;
  text-align: center;
  padding: 30px 10px;
}

  .thr-img img{
        width: 70rem;
  }
}


@media screen and (max-width: 490px) {
.row-1 p{
  font-size: 15px;
  font-weight: 100;
  font-family: "uni-thin";
}
.row-2 p{
  font-size: 15px;
  font-weight: bold;
  font-family: "Mont-Regular";
  padding: 5px 0px;
}
.row-3 p{
  font-size: 13px;
  font-weight: 100;
  font-family: "Mont-light";
  padding: 5px 0px;
}
.row-4{
  display: flex;
  justify-content: space-evenly;
  padding: 0px 10px;
  padding-left: 27px;

}

.End-price img{
  width: 45px;
  height: 45px;
  float: left;
}
.Start-price img{
  width: 45px;
  height: 45px;
  float: left;
}
.AED-content{
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
}
.END-content{
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
}
.row-5 button{
    margin-top: 30px;
    padding: 8px 18px;
    border-radius: 20px;
    font-size: 10px;
    border: 1px solid grey;
}

.bannercontent {
  color: white;
  text-align: center;
  padding: 10px 20px;
}
}
@media screen and (max-width: 768px) {
  .sidebar {
    width: 300px;
  }
}

@media screen and (max-width: 576px) {
  .sidebar {
    width: 250px;
  }
}

/* second-section */

.wrapper-sec2{
  text-align: center;
}

  .nav-tabs {
    display: flex;
    justify-content: center; /* Center the tabs horizontally */
    border: none;
    font-family: "Mont-light";

  
  }
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #8e8b8b;
    cursor: default;
    background-color: #191a1f;
    border: none;
    border-bottom: 1px solid saddlebrown;
}

    .nav-tabs>li>a{
      color: white;
      font-size: 20px;
  }

  .nav-tabs>li>a:hover{
    background-color: #191a1f;
    border: none;
    border-bottom: 1px solid saddlebrown;
  }
  .cardWrap{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px 10px;
    position: relative;
    margin: 25px 10px;
  }
  .sec-2-title{
      font-family: "uni-thin";
      color: white;
      font-size: 35px;
      font-weight: 100;
      margin-top: 45px;

  }
  .imagecard-container{
    text-align: center;
    margin: 90px 10px 10px 20px;  
  }

    .imagecard-container h1{
      color: white;
          font-family: "uni-thin";
      font-weight: 100;
          font-size: 42px;
  }
      .imagecard-container p{
      color: rgb(186, 185, 185);
          font-family: "uni-thin";
          font-weight: 100;
          font-size:17px ;
  }





/* third section */

  .third-section{
  background-image: url("../images/The\ Lux\ Dubai\ text\ n\ fonts\ UI-UX-05.webp");
  background-size: cover; /* Cover the entire container */
  height: auto;
  background-position: center;
  }

  .thr-content{
    text-align: center;
    padding: 164px 40px;
    color: rgb(223, 223, 223);
  }
  .thr-content h1{
    font-size: 47px;
    font-family: "uni-thin";
    font-weight: 100;
  }
  .thr-content p{
    font-size: 18px;
    font-family: "uni-thin";
    font-weight: 100;

  }
  .thr-img img{
        width: 70rem;
  }
  .thr-sec-p1{
    display: flex;
    justify-content: space-around;
    padding-top: 50px;
  }

  .img-card-wrapper{
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    position: relative;
  }


/* fourth section */

.fourth-section{
  background-image: url("../images/The\ Lux\ Dubai\ text\ n\ fonts\ UI-UX-06.webp");
  background-size: cover; /* Cover the entire container */
  height: auto;
  /* opacity: 80%; */
  background-position: center;
  position: relative;
}

.fourth-container{
  padding: 64px 0px;
  display: flex;
  justify-content: space-around
}

.fourth-content{
    width: 45%;
    padding: 60px 0px;
      text-align: left;
}
.fourth-content h1{
  font-size: 50px;
  font-family: "uni-thin";
  font-weight: 100;
  color: rgb(255, 252, 252);
}
.fourth-content p{
    font-size: 22px;
    padding: 14px 0px;
    line-height: 31px;
    font-family: "Mont-light";
    font-weight: 100;
    color: rgb(255, 252, 252);
    word-spacing: 5px;
}
.fourth-sec-logo{
  text-align: left;
  width: 400px;
  height: 205px;
}
.forth-section-image{
  width: 450px;
}

.fourth-content button{
    padding: 14px 40px;
    border-radius: 20px;
    font-size: 18px;
    font-family: "Mont-light";
    font-weight: 100;
    background-color: #c38b74;
    border: 1px solid grey;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
}
.fourth-button-arrow{
    rotate: 40deg;
    padding: 0px 10px;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  transition: 0.3s ease; /* Smooth transition for background color */

}

.modal-content {
    background-color: rgb(0, 0, 0);
    padding: 25px;
    border-radius: 8px;
    width: 80%;
    text-align: center;
    height: auto;

}
.modal-display-flex {
    display: flex;
    justify-content: space-around;
    margin: 0px;
    width: 100%;
    }
.modal-input-txt {
    color: white;
    font-family: 'Mont-Light';
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 15px;
    padding: 1px 0px;
    margin: 20px 7px;
    width: 100%;
}
.modal-input-txt::placeholder, .modal-input-email::placeholder, .modal-input-num::placeholder{
  color: white;
}
.modal-input-num {

    color: white;
    font-family: 'Mont-Light';
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 15px;
    padding: 1px 0px;
    margin: 20px 7px;
    width: 100%;
    }
.modal-input-email {
    color: white;
    font-family: 'Mont-Light';
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 15px;
    padding: 1px 0px;
    margin: 20px 7px;
    width: 100%;}
.catalog-modal-content {
    margin-top: 0px;
}
.catalog-modal-form{
  text-align: left;
  margin-left: 25px;
}
.modal-submit-btn{
    width: 145px;
    margin-top: 20px;
    padding: 5px 5px;
    border-radius: 10px;
    border: none;
    background-color: #c38b74;
    color: white;
    font-family: "Mont-light";
    font-weight: 100;
}
.catalog-modal-image{
      width: fit-content;
}
.modal-image{
  width: 100%;
  height: -webkit-fill-available;

}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

h1.catalog-modal-heading {
    color: white;
    font-weight: 100;
    font-family: 'uni-thin';
    font-size: 37px;
    padding: 20px;
}
/* --- */


/* fivth section */

.fivth-section{
  padding: 20px;
  height: auto;
}
.fivth-sec-title{
text-align: center;
padding: 40px 10px;
color: white;
 font-size: 50px;
  font-family: "uni-thin";
  font-weight: 100;
}

img.fivth-sec-img1 {
    width: 300px;
    height: 214px;
    margin: 20px 0px;
  }

.fivth-sec-box-1 {
    display: flex;
    justify-content: space-around;
    width: 45%;
    height: auto;
    background: radial-gradient(ellipse at center, #d0e4f7 0%,#474143 0%,#302c2d 53%,#302c2d 78%,#302c2d 100%);
    box-shadow: 1px 0px 19px 1.1px #000000a6;
    color: white;
    margin: 0px 10px;
}

.fivth-sec-box-2 {
display: flex;
    justify-content: space-around;
    width: 45%;
    height: auto;
    box-shadow: 1px 0px 19px 1.1px #000000a6;
    background: radial-gradient(ellipse at center, #d0e4f7 0%,#474143 0%,#302c2d 53%,#302c2d 78%,#302c2d 100%);
    color: white;
    margin: 0px 10px;

}

.fivth-row1 {
    margin: 10px;
    display: flex;
    justify-content: center;
}
.fivth-row2 {
    margin: 10px;
    display: flex;
    justify-content: center;
}
.content-box1{
  padding: 40px 10px;
}
.content-box2{
  padding: 40px 10px;
}
.content-box1 h1{
  font-family: "uni-thin";
  font-weight: 100;
  padding: 10px 0px;
}
.content-box1 p{
  font-family: "Mont-light";
  font-weight: 100;
    padding: 10px 0px;
}

.content-box2 h1{
  font-family: "uni-thin";
  font-weight: 100;
  padding: 10px 0px;
}
.content-box2 p{
  font-family: "Mont-light";
  font-weight: 100;
    padding: 10px 0px;
}
.fivth-sec-button{
  padding: 8px 30px;
    border-radius: 20px;
    font-size: 14px;
    font-family: "Mont-light";
    font-weight: 100;
    background-color: #c38b74;
    border: 1px solid grey;
    color: rgb(255, 255, 255);
    margin: 20px 0px;
}

/*  */



/* sixth section */
.sixth-section {
    padding: 10px 88px;
    height: auto;
}

img.sixth-quiz-image {
    width: 100%;
    height: 62vh;

}
/* .sixth-left-image{
  height:70vh;
} */

.sixth-container {
    display: flex;
    /* justify-content: space-around; */
    margin-top: 100px;
    background: radial-gradient(ellipse at center, #d0e4f7 0%,#474143 0%,#302c2d 53%,#302c2d 78%,#302c2d 100%);
    box-shadow: 1px 0px 19px 1.1px #000000a6;
;
    position: relative;
}

.sixth-content {
    width: 70%;
    padding: 46px 25px;
}
.sixth-content p,span{
  color: white;
    font-family: "Mont-light";
  font-weight: 100;
  word-spacing: 5px;
  font-size: 15px;
}
.sixth-content h3{
  color: white;
    font-family: "Mont-light";
  font-weight: 100;
  word-spacing: 5px;
  font-size: 27px;
  padding: 0px 0px 20px 0px;
}
.sixth-content button{
    padding: 8px 40px;
    border-radius: 20px;
    font-size: 14px;
    font-family: "Mont-light";
    font-weight: 100;
    background-color: #c38b74;
    border: 1px solid grey;
    color: rgb(255, 255, 255);
    margin: 20px 0px;
}
svg.svg-inline--fa.fa-circle-check {
    font-size: 50px;
    color: #c38b74;
    border: 3px solid black;
    border-radius: 26px;
    position: absolute;
    right: -20px;
    top: -21px;
}


  /* seventh section */

img.map {
    width: 100%;
    height: 80vh;
}
.seventh-section h1 {
    text-align: center;
    padding: 40px 10px;
    color: white;
    font-size: 50px;
    font-family: "uni-thin";
    font-weight: 100;
}
.seventh-section {
    padding: 20px 0px;
}
  /*  */


/* eightth section */

.eight-section{
    padding: 10px 0px;
    height: auto;
}
.image-container.text-white.p-3.img-1, .image-container.text-white.p-3.img-2, .image-container.text-white.p-3.img-3, .image-container.text-white.p-3.img-4, .image-container.text-white.p-3.img-5, .image-container.text-white.p-3.img-6 {
    position: relative;
}
.innerImageText.img-1{
 margin: 0px;
    padding: 0px;
    position: absolute;
    bottom: 22px;
    font-size: 27px;
    left: 10px;
    text-align: left;
    }
    
.innerImageText.img-2{
 margin: 0px;
    padding: 0px;
    position: absolute;
    bottom: 22px;
    font-size: 27px;
    left: 10px;
    text-align: left;
    }
.innerImageText.img-3{
 margin: 0px;
    padding: 0px;
    position: absolute;
    bottom: 22px;
    font-size: 27px;
    left: 10px;
    text-align: left;}
.innerImageText.img-4{
 margin: 0px;
    padding: 0px;
    position: absolute;
    bottom: 22px;
    font-size: 27px;
    left: 10px;
    text-align: left;
    }
.innerImageText.img-5{
   margin: 0px;
    padding: 0px;
    position: absolute;
    bottom: 22px;
    font-size: 27px;
    left: 10px;
    text-align: left;}

.innerImageText.img-6{
  margin: 0px;
    padding: 0px;
    position: absolute;
    bottom: 22px;
    font-size: 27px;
    left: 10px;
    text-align: left;
    }

.eight-section h1 {
    text-align: center;
    padding: 40px 10px;
    font-size: 50px;
    color: white;
    font-family: "uni-thin";
    font-weight: 100;
}
.luxary-18{
  width: 100%;
}
.luxary-19{
  width: 100%;
}
.luxary-20{
  width: 100%;
}
.luxary-21{
  width: 100%;
}
.luxary-22{
  width: 100%;
}
.luxary-23{
  width: 100%;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 20px;

}

.image-container img {
  transition: transform 0.3s ease; /* Smooth transition */
}

.image-container:hover img {
  transform: scale(1.2); /* Zoom effect */
  opacity: 50%;
  border-radius: 20px;
  background-color: #10101092;
}

/*  */

/* ninth section */


.ninth-content {
    display: flex;
    background: radial-gradient(ellipse at center, #d0e4f7 0%,#474143 0%,#302c2d 53%,#302c2d 78%,#302c2d 100%);
    height: auto;
    flex-wrap: wrap;
    padding: 43px 30px;
}


.stay-tuned-content {
    display: flex;
    justify-content: center;
}

img.ninth-bell-icon {
    width: 87px;
    margin: 15px;
}

.stay-tuned-text {
    width: 100%;
    color: white;
    font-size: 14px;
    padding: 5px 5px;
    position: relative;
}
.ninth-thumb{
  position: absolute;
    width: 36px;
    right: 10px;
    top: -21px;
}

.stay-tuned-content {
    width: 50%;
}


.stay-tuned-text  h3 {
    font-size: 22px;
    color: white;
    font-family: "uni-thin";
    font-weight: 100;
}
.stay-tuned-text  p {
    font-size: 14px;
    color: rgb(158, 158, 158);
    font-family: "Mont-light";
    font-weight: 100;
}


.ninth-section {
    margin-top: 35px;
    margin-bottom: 15px;
}

.ninth-form {
  margin-top: 12px;
  padding: 10px 35px;

}

.ninth-input-txt {
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 17px;
    padding: 1px 9px;
    margin: 10px;
    color: white;
}
.ninth-input-email {
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 17px;
    padding: 1px 9px;
    margin: 10px;
        color: white;
}
.ninth-input-num {
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 17px;
    padding: 1px 9px;
    margin: 10px;
        color: white;
}

input[type="email"]:focus{
  outline: none;
    border-bottom: 1px solid #f78e65;

} 
input[type="number"]:focus{
  -webkit-appearance: textfield; /* Remove default spinner */
  -moz-appearance: textfield; /* Remove default spinner for Firefox */
  appearance: textfield; 
  outline: none;
    border-bottom: 1px solid #f78e65;
} 
input[type="text"]:focus{
  outline: none;
  border-bottom: 1px solid #f78e65;
} 
.sub-now-button button{
  padding: 11px 55px;
    border-radius: 5px;
    border: none;
    background-color: #c40f0f;
    color: white;
    margin-top: 45px;
    position: relative;
}
.sub-bell-icon{
    position: absolute;
    color: white;
    font-size: 24px;
    right: 5px;
    top: 7px;}

/*  */

@media screen and (max-width: 1150px) {
  .thr-img img{
        width: 50rem;
  }
    .thr-content{
    text-align: center;
    padding: 164px 20px;
    color: rgb(223, 223, 223);
  }
  .thr-content h1{
    font-size: 37px;
    font-family: "uni-thin";
    font-weight: 100;
  }
  .thr-content p{
    font-size: 14px;
    font-family: "uni-thin";
    font-weight: 100;

  }
  .catalog-modal-content {
    margin-top: 0px;
}

  .fivth-sec-box-1,.fivth-sec-box-2 {
    display: block;
    width: 45%;
    height: auto;
    background-color: #414040;
        box-shadow: 1px 0px 5px 1.1px #8b8a8aa6;
    color: white;
    margin: 0px 10px;
}
img.fivth-sec-img1,.img.fivth-sec-img2 {
    width: 300px;
    height: 214px;
    margin: 0px 0px;
}
.content-box1,.content-box2 {
    padding: 0px 10px;
}
.catalog-modal-content {
    margin-top: 0px;
}


}
@media screen and (max-width: 1050px) {

.fourth-content h1{
  font-size: 40px;
  font-family: "uni-thin";
  font-weight: 100;
  color: rgb(255, 252, 252);
}
.fourth-content p{
    font-size: 17px;
    padding: 14px 0px;
    line-height: 31px;
    font-family: "Mont-light";
    font-weight: 100;
    color: rgb(255, 252, 252);
    word-spacing: 2px;
}
.fourth-content button{
    padding: 14px 30px;
    border-radius: 20px;
    font-size: 14px;
    font-family: "Mont-light";
    font-weight: 100;
    background-color: #c38b74;
    border: 1px solid grey;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
}
.fourth-sec-logo{
  text-align: left;
  width: 300px;
  height: 165px;
}
.forth-section-image{
  width: 400px;
}
.fourth-content{
    width: 37%;
    padding: 50px 0px;
      text-align: left;
}
.sixth-content {
    width: 70%;
    padding: 0px 13px;
}
.sixth-content p, span {
    color: white;
    font-family: "Mont-light";
    font-weight: 100;
    word-spacing: 5px;
    font-size: 14px;
}
.sixth-content button{
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 12px;
    font-family: "Mont-light";
    font-weight: 100;
    background-color: #c38b74;
    border: 1px solid grey;
    color: rgb(255, 255, 255);
    margin: 20px 0px;
}
}  


@media screen and (max-width: 950px){
  .modal-display-flex {
    display: block;
    margin: 0px;
    width: 100%;
    }
    .modal-image{
      width: 45%;
    }
    h1.catalog-modal-heading {
    color: white;
    font-weight: 100;
    font-family: 'uni-thin';
    font-size: 21px;
    padding: 10px;
}
    .catalog-modal-content {
    margin-top: 0px;
}

}



@media screen and (max-width: 850px) {
 .thr-sec-p1{
   display: block;
    padding-top: 50px;
  }
  .thr-img {
        text-align: center;
  }
  .nav-tabs>li>a {
    color: white;
    font-size: 18px;
}
.thr-content{
    text-align: center;
    padding: 64px 40px;
    color: rgb(223, 223, 223);
  }
  .forth-section-image{
  width: 350px;
}
.fourth-content h1{
  font-size: 30px;
  font-family: "uni-thin";
  font-weight: 100;
  color: rgb(255, 252, 252);
}
.fourth-sec-logo{
  text-align: left;
  width: 200px;
  height: 105px;
}
.fourth-content{
    width: 45%;
    padding: 50px 0px;
      text-align: left;
}
.sixth-container {
    display: block;
    /* justify-content: space-around; */
    margin-top: 100px;
    background-color: #2a2a2ab5;
}
.sixth-content {
    width: 100%;
    padding: 46px 25px;
}
.sixth-left-image{
  text-align: center;
}
.sixth-section {
    padding: 10px 20px;
    height: auto;
}

}
@media screen and (max-width: 768px) {
.fourth-container{
  padding: 64px 0px;
  display: block;
  text-align: center;
}
.fourth-content h1{
  font-size: 40px;
  font-family: "uni-thin";
  font-weight: 100;
  color: rgb(255, 252, 252);
}
.fourth-content p{
    font-size: 17px;
    padding: 14px 0px;
    line-height: 31px;
    font-family: "Mont-light";
    font-weight: 100;
    color: rgb(255, 252, 252);
    word-spacing: 2px;
}
.fourth-content button{
    padding: 14px 30px;
    border-radius: 20px;
    font-size: 14px;
    font-family: "Mont-light";
    font-weight: 100;
    background-color: #c38b74;
    border: 1px solid grey;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
}
.fourth-sec-logo{
  text-align: left;
  width: 300px;
  height: 165px;
}
.forth-section-image{
  width: 500px;
}
.fourth-content{
    width: 100%;
    text-align: center;
    padding: 50px 20px;
    text-align: left;
}
.fivth-row1, .fivth-row2 {
    margin: 10px;
    display: block;
}
.fivth-sec-box-1, .fivth-sec-box-2 {
    display: block;
    width: 100%;
    height: auto;
    background-color: #414040;
    box-shadow: 1px 0px 5px 1.1px #8b8a8aa6;
    color: white;
    margin: 20px 10px;
}

} 
@media screen and (max-width: 697px) {
.stay-tuned-content{
  width: 100%;
}
.swiper-slide-duplicate{
  width:720px;
}
.ninth-content {
    display: flex;
    background-color: #101010;
    height: auto;
    flex-wrap: wrap;
    padding: 43px 10px;
}
}
@media screen and (max-width: 650px) {
 .thr-sec-p1{
   display: block;
    padding-top: 50px;
  }
  .thr-img {
        text-align: center;
  }
  .nav-tabs>li>a {
    color: white;
    font-size: 10px;
}
.forth-section-image{
  width: 450px;
}

.modal-input-txt {
    color: white;
    font-family: 'Mont-Light';
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 15px;
    padding: 1px 0px;
    margin: 20px 7px;
    width: 100%;
}
.modal-input-email {
    color: white;
    font-family: 'Mont-Light';
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 15px;
    padding: 1px 0px;
    margin: 20px 7px;
    width: 100%;
}
.modal-input-num {
     color: white;
    font-family: 'Mont-Light';
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 15px;
    padding: 1px 0px;
    margin: 20px 7px;
    width: 100%;
}
}
@media screen and (max-width: 550px) {
.fourth-content h1{
  font-size: 30px;
  font-family: "uni-thin";
  font-weight: 100;
  color: rgb(255, 252, 252);
}
.fourth-content{
    text-align: center;
    padding: 50px 10px;
      text-align: left;
}
.fourth-content p{
    font-size: 14px;
    padding: 14px 0px;
    line-height: 31px;
    font-family: "Mont-light";
    font-weight: 100;
    color: rgb(255, 252, 252);
    word-spacing: 1px;
}
.forth-section-image{
  width: 300px;
}
  .thr-img img{
        width: 30rem;
  }
}
